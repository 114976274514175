<template>
    <v-card>
        <v-card-text>
            <video class="video-js" id="sec-vid" controls preload="auto" width="640px" height="360px"
                v-show="video && video.videoURL" style="width: 100%; height: calc(100vh - 280px)"
                oncontextmenu="return false;"></video>

            <h3 class="mt-3" v-if="video">{{ video.title }}</h3>

            <h3 class="text-center" v-if="!video">No Video Available</h3>
        </v-card-text>
    </v-card>
</template>

<script setup>
import { ref, inject, onMounted, onBeforeUnmount, nextTick } from "vue";
import { useRoute } from "vue-router";
import * as vlService from '@/services/videoLibraryService';
import videojs from "video.js";
// import "video.js/dist/video-js.css";

const loader = inject("loader");
const snackbar = inject("snackBar");
const route = useRoute();

const videoId = ref(null);
const video = ref(null);
const player = ref(null);

async function getData() {
    loader.show();

    const result = await vlService.getById(videoId.value)
        .catch(ex => snackbar.error("Error", ex));

    if (result) {
        try {
            video.value = result;

            await nextTick();

            const videoElement = document.getElementById("sec-vid");

            player.value = videojs(videoElement, {
                controls: true,
                autoplay: false,
                preload: "auto",
                sources: [
                    {
                        src: video.value.videoURL,
                        type: "video/mp4",
                    },
                ],
            });
        }
        catch (ex) {
            snackbar.error(ex);
        }
    }

    loader.hide();
}

onMounted(async () => {
    videoId.value = route.params.v;

    await getData();
});

onBeforeUnmount(() => {
    if (player.value) {
        player.value.dispose();
    }
});
</script>