import { apiCall } from "@/setup/webApi";

export const master_getAll = (pageContext) => {
  const queryString = new URLSearchParams(pageContext).toString();

  return new Promise((resolve, reject) => {
    apiCall("get", `Attendance/master/all?${queryString}`)
      .then((response) => {
        resolve(response);
      })
      .catch((ex) => {
        reject(ex);
      });
  });
};

export const master_getById = (id) => {
  return new Promise((resolve, reject) => {
    apiCall("get", `Attendance/master/${id}`)
      .then((response) => {
        resolve(response);
      })
      .catch((ex) => {
        reject(ex);
      });
  });
};

export const master_add = (masterAttendance) => {
  return new Promise((resolve, reject) => {
    apiCall("post", `Attendance/master`, masterAttendance)
      .then((response) => {
        resolve(response);
      })
      .catch((ex) => {
        reject(ex);
      });
  });
};

export const master_update = (id, masterAttendance) => {
  return new Promise((resolve, reject) => {
    apiCall("put", `Attendance/master/${id}`, masterAttendance)
      .then((response) => {
        resolve(response);
      })
      .catch((ex) => {
        reject(ex);
      });
  });
};

export const master_delete = (id) => {
  return new Promise((resolve, reject) => {
    apiCall("delete", `Attendance/master/${id}`)
      .then((response) => {
        resolve(response);
      })
      .catch((ex) => {
        reject(ex);
      });
  });
};

export const detail_getAllByMasterId = (id) => {
  return new Promise((resolve, reject) => {
    apiCall("get", `Attendance/detail/${id}`)
      .then((response) => {
        resolve(response);
      })
      .catch((ex) => {
        reject(ex);
      });
  });
};

export const detail_add = (id, formData) => {
  return new Promise((resolve, reject) => {
    apiCall("post", `Attendance/detail/${id}`, formData)
      .then((response) => {
        resolve(response);
      })
      .catch((ex) => {
        reject(ex);
      });
  });
};

export const detail_delete = (id, itsId) => {
  return new Promise((resolve, reject) => {
    apiCall("delete", `Attendance/detail/${id}/${itsId}`)
      .then((response) => {
        resolve(response);
      })
      .catch((ex) => {
        reject(ex);
      });
  });
};

export const admins_getAllByMasterId = (id) => {
  return new Promise((resolve, reject) => {
    apiCall("get", `Attendance/${id}/admins`)
      .then((response) => {
        resolve(response);
      })
      .catch((ex) => {
        reject(ex);
      });
  });
};

export const admins_add = (id, formData) => {
  return new Promise((resolve, reject) => {
    apiCall("post", `Attendance/${id}/admins`, formData)
      .then((response) => {
        resolve(response);
      })
      .catch((ex) => {
        reject(ex);
      });
  });
};

export const admins_delete = (id, itsId) => {
  return new Promise((resolve, reject) => {
    apiCall("delete", `Attendance/${id}/admins/${itsId}`)
      .then((response) => {
        resolve(response);
      })
      .catch((ex) => {
        reject(ex);
      });
  });
};