<template>
    <v-app>
        <v-container fluid class="fill-height">
            <v-card class="w-100 fill-height">
                <v-card-text>
                    <v-img :src="require(`@/assets/safarsawari_logo.jpeg`)" height="175px" class="mb-5"></v-img>
                    <v-stepper :items="['Packages', 'Registration', 'Group Members']" v-model="step" hide-actions
                        mandatory alt-labels>
                        <template v-slot:[`item.1`]>
                            <p class="text-h6 text-center mb-3 text-red">Click on the package that you want to select
                            </p>
                            <v-row>
                                <v-col md="4" cols="12">
                                    <v-img :src="require(`@/assets/safar_01.jpeg`)" @click="step = 2" />
                                </v-col>
                                <v-col md="4" cols="12">
                                    <v-img :src="require(`@/assets/safar_01.jpeg`)" @click="step = 2" />
                                </v-col>
                                <v-col md="4" cols="12">
                                    <v-img :src="require(`@/assets/safar_01.jpeg`)" @click="step = 2" />
                                </v-col>
                            </v-row>
                        </template>

                        <template v-slot:[`item.2`]>
                            <v-form @submit.prevent="step = 3">
                                <v-row>
                                    <v-col md="4" offset-md="4">
                                        <p class="text-h4 mb-3">KUN Package (9 Days)</p>

                                        <p>Enter your ITS Number</p>
                                        <v-text-field />

                                        <p>Enter your full name as per ITS</p>
                                        <v-text-field />

                                        <p>Enter your contact number</p>
                                        <p><small>Format: 03xxxxxxxxx</small></p>
                                        <v-text-field />

                                        <p>Enter your WhatsApp number</p>
                                        <p><small>Format: 03xxxxxxxxx</small></p>
                                        <v-text-field />

                                        <p>Enter your full name as per Passport</p>
                                        <v-text-field />

                                        <p>Select your date of birth</p>
                                        <v-text-field />

                                        <p>Enter any special requests</p>
                                        <v-text-field />

                                        <div class="d-flex flex-row justify-space-between">
                                            <v-btn @click="step = 1">Back</v-btn>
                                            <v-btn type="submit">Next</v-btn>
                                        </div>
                                    </v-col>
                                </v-row>
                            </v-form>
                        </template>

                        <template v-slot:[`item.3`]>
                            <v-card variant="outlined">
                                <v-card-text>
                                    <p>Enter member's ITS Number</p>
                                    <v-text-field />

                                    <p>Enter member's full name as per ITS</p>
                                    <v-text-field />

                                    <p>Enter member's WhatsApp number</p>
                                    <p><small>Format: 03xxxxxxxxx</small></p>
                                    <v-text-field />

                                    <p>Enter member's full name as per Passport</p>
                                    <v-text-field />

                                    <v-btn>Add</v-btn>
                                </v-card-text>
                            </v-card>
                            <p class="text-center mt-5">No Group Members Added</p>

                            <div class="mt-5 d-flex flex-row justify-space-between">
                                <v-btn @click="step = 2">Back</v-btn>
                                <v-btn>Submit</v-btn>
                            </div>

                        </template>
                    </v-stepper>
                </v-card-text>
            </v-card>
        </v-container>
    </v-app>
</template>

<script setup>
import { ref } from 'vue';

const step = ref(1);

</script>