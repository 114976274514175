import { apiCall } from "@/setup/webApi";

export const getById = (id) => {
  return new Promise((resolve, reject) => {
    apiCall("get", `VideoLibrary/${id}`)
      .then((response) => {
        resolve(response);
      })
      .catch((ex) => {
        reject(ex);
      });
  });
};